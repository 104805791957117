/**
 * Created by karthikeyan.k on 06-02-2019.
 */

import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { ErrorPopupService } from '../services/error.popup.service';
import { UserService } from '../services/user.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    public errorPopupService: ErrorPopupService,
    public userService: UserService
  ) {
    //console.log('InterCeptor');
    //console.log(this.errorPopupService);
    this.errorPopupService.testMethod();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let status = '';
    //console.log('InterCeptor');
    //console.log(request);
    if (('' + request.url).includes('DetailedGameHistory')) {
    } else {
      if (
        this.userService.user.sessiontoken != undefined &&
        this.userService.user.sessiontoken != ''
      ) {
        request = request.clone({
          setHeaders: { sessiontoken: this.userService.user.sessiontoken },
        });
      }
    }
    // requirement for session end popup
    /*
SESSION_SLEEP_TIME 15 min
SESSION_IDLE_TIME 60 min
SESSION_BROW_CLOSE_TIME 15 min
 */
    return next.handle(request).pipe(
      tap(
        (event) => {
          //console.log('success');
          status = 'success';
        },
        (error) => {
          status = error.status;
          //console.log('Intercept Error');
          //console.log(error);
        }
      ),
      finalize(() => {
        if (status !== 'success') {
          //console.log('InterCeptor Finalize');
          //console.log(this);
          //console.log(this.errorPopupService);
          this.errorPopupService.showPopup();
        }
      })
      /*map((event: HttpEvent<any>) => {
         if (event instanceof HttpResponse) {
         //console.log('event--->>>', event);
         }
         return event;
         }),
         catchError(error => {
         // if (error instanceof HttpErrorResponse) {

         // }
         throwError(error);

         })*/
    );
  }
}
