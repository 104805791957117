import { Injectable } from '@angular/core';
import { PageConstant } from './constant/page.constant';
import { Settings } from './models/settings/settings';
import { GIInfoService } from './services/giinfo.service';
import { environment } from 'src/environments/environment';

// app-init.service.ts
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  // Inject necessary services here
  constructor(public giInfoService: GIInfoService) {}

  // app-init.service.ts
  public initAppParams(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      try {
        this.loadVersion();
        resolve(); // Only if everything goes well
      } catch (error) {
        reject(error); // If an error occurs, reject the promise
      }
    });
  }

  loadVersion = () => {
    let URL = 'version.json';

    if (environment.envName === 'dev') {
      URL = 'assets/version.json';
    }

    this.giInfoService.getJSONFileData(URL).subscribe((getJSONData) => {
      PageConstant.VERSION = getJSONData.assetsVersion;
      this.loadAppSettings();
    });
  };

  loadAppSettings = () => {
    let URL = 'appsettings.json';

    if (environment.envName === 'dev') {
      URL = 'assets/appsettings.json';
    }

    this.giInfoService.getJSONFileData(URL).subscribe((getJSONData) => {
      Settings.appSettings = getJSONData.application;
    });
  };
}
